import './AudiencePicker.css'
import Audiences from './Audiences'
import { Checkbox, FormControlLabel } from '@mui/material'

const toggleAudience = (setAudience, checkedAudience) => (audience) => {
  if (checkedAudience !== 0) {
    setAudience(0)
  } else {
    setAudience(audience)
  }
}

const AudienceButton = ({
  audience,
  displayName,
  handleChange,
  checkedAudience,
  isDisabled,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={() => handleChange(audience)}
          checked={audience === checkedAudience}
          disabled={isDisabled}
        />
      }
      label={displayName}
    ></FormControlLabel>
  )
}

const AudiencePicker = ({ setAudience, checkedAudience, isDisabled }) => {
  return (
    <div className="radio-buttons-wrapper">
      <AudienceButton
        audience={Audiences.AMERICAN_LIBERALS}
        displayName={'🗽 American Liberals '}
        handleChange={toggleAudience(setAudience, checkedAudience)}
        checkedAudience={checkedAudience}
        isDisabled={isDisabled}
      />
    </div>
  )
}

export default AudiencePicker
