import { googleLogout } from '@react-oauth/google'
import { AUTH_TOKEN_KEY } from '../Consts'

const logout = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY)
  googleLogout()
  window.location.reload()
}

export default logout
