import axios from 'axios';

var host = 'https://narratively-backend.azurewebsites.net'
    if (window.location.host.startsWith('localhost'))
host = 'http://localhost:5000'

const apiInstance = axios.create({
  baseURL: host,
  headers: {
    'x-version': 1,
    'api-key': process.env.REACT_APP_API_KEY
  },
});

// // Set the default version header
// apiInstance.defaults.headers.common['x-version'] = 2; // Replace with your desired version

export default apiInstance;
