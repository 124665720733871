import { Box, CircularProgress, Typography } from '@mui/material'
import './ParseTextLoader.css'

const ParseTextLoader = () => {
  return (
    <div style={{ display: 'flex', marginTop: '-12px', marginRight: '3px' }}>
      <Box
        id="circularLoader"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '60px',
          alignItems: 'center',
        }}
      >
        <CircularProgress style={{ width: 20, height: 20 }} />
      </Box>
      <div class="animateTextBox" id="animateTextBox">
        <div class="loaderBox">
          <div class="animateText text-center">
            <div>
              <Typography variant="caption">Assessing text...</Typography>
            </div>
            <div>
              <Typography variant="caption">Applying AI models...</Typography>
            </div>
            <div>
              <Typography variant="caption">Refining mistakes...</Typography>
            </div>
            <div>
              <Typography variant="caption">
                Improving coherence and clarity...
              </Typography>
            </div>
            <div>
              <Typography variant="caption">
                Adjusting to global narratives...
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParseTextLoader
