import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

import Preloader from './components/preloader/Preloader'
import Navbar from './components/navbar/Navbar'
import Home from './screens/home/Home'
import PrivacyPolicyPage from './screens/privacy-policy/PrivacyPolicy'
import { ThemeContext } from './context/Theme'
import ScrollToTop from './components/scroll-to-top/ScrollToTop'
import './App.css'
import useLogoutOnExpiredToken from './hooks/useLogoutOnExpiredToken'
import { UserContextProvider } from './hooks/useUserMetadata'
import useAuthToken from './hooks/useAuthToken'
import GoogleLoginScreen from './screens/login/GoogleLoginScreen'
import ContentReview from './screens/content-review/ContentReview.tsx'

function App() {
  const [load, upadateLoad] = useState(true)
  const [{ themename }] = React.useContext(ThemeContext)
  const [authToken] = useAuthToken()

  useLogoutOnExpiredToken(authToken)

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false)
    }, 1200)

    return () => clearTimeout(timer)
  }, [])

  if (!authToken) {
    return <GoogleLoginScreen />
  }

  return (
    <UserContextProvider authToken={authToken}>
      <div className={`${themename} app`}>
        <Router>
          <Preloader load={load} />
          <div
            className="App"
            id={load ? 'no-scroll' : 'scroll'}
            style={{ display: 'Flex', flexDirection: 'column', height: '100%' }}
          >
            <Navbar />
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/content-review" element={<ContentReview />} />
              <Route path="/privacy" element={<PrivacyPolicyPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            {/* <Footer /> */}
          </div>
        </Router>
      </div>
    </UserContextProvider>
  )
}

export default App
