import { useState, useEffect } from 'react'
import { AUTH_TOKEN_KEY } from '../Consts'

const useAuthToken = () => {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem(AUTH_TOKEN_KEY)
  )

  useEffect(() => {
    const handleAuthTokenChange = () => {
      const authToken = localStorage.getItem(AUTH_TOKEN_KEY)
      setAuthToken(authToken)
    }

    window.addEventListener('storage', handleAuthTokenChange)

    return () => {
      window.removeEventListener('storage', handleAuthTokenChange)
    }
  }, [])

  return [authToken, setAuthToken]
}

export default useAuthToken
