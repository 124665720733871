import React from 'react'
import './PrivacyPolicy.css'
import { NARRATIVELY_SUPPORT_EMAIL } from '../../Consts'

const PrivacyPolicyPage = () => {
  const mailToAddress = `mailto:${NARRATIVELY_SUPPORT_EMAIL}`
  return (
    <div className="container">
      <div className="privacy-policy">
        <div className="policy-box">
          <h1 varient="h1">Narratively Privacy Policy</h1>
          <p>
            <strong>Last Updated:</strong> 29.12.2023
          </p>

          <p>Hello from Narratively!</p>

          <h2>1. Your Content</h2>
          <p>
            When you share posts with us for editing, we save them in our
            database in order to constantly improve. Don't worry, we'll keep it
            safe.
          </p>

          <h2>2. Google Authentication</h2>
          <p>
            If you choose to authenticate using Google, we securely collect and
            store the necessary information to ensure a seamless and
            personalized experience. This includes your name and email address.
          </p>

          <p>
            If you have any questions or concerns about your privacy, feel free
            to contact us at{' '}
            <a href={mailToAddress}>{NARRATIVELY_SUPPORT_EMAIL}</a>.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyPage
