import { useEffect } from 'react'
import { jwtDecode } from 'jwt-decode'

import logout from '../utils/Logout'

const useLogoutOnExpiredToken = (authToken) => {
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (!authToken) {
        return
      }
      const decodedToken = jwtDecode(authToken)
      const currentTime = Date.now() / 1000

      if (decodedToken.exp < currentTime) {
        logout()
      }
    }
    // Run the function once immediately and then every 60 seconds
    checkTokenExpiration()
    const intervalId = setInterval(checkTokenExpiration, 60000)

    // Clean up function to clear the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [authToken])
}

export default useLogoutOnExpiredToken
