import React from 'react'
import ReactDOM from 'react-dom/client'
import { GoogleOAuthProvider } from '@react-oauth/google'

import './Index.css'
import App from './App'
import reportWebVitals from './ReportWebVitals'
import { ThemeProvider } from './context/Theme'

import 'tippy.js/dist/tippy.css' // optional for styling

const root = ReactDOM.createRoot(document.getElementById('root'))
// todo: move to env vars / secret + generate clientId which is project owned
const clientId =
  '837634215648-9h90c92c8p2im9h1i0bh57aqkp0358e0.apps.googleusercontent.com'

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <GoogleOAuthProvider clientId={clientId}>
        <App />
      </GoogleOAuthProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
