const Grades = {
  Good: 1,
  Neutral: 0,
  Bad: -1,
}

const gradeMap = new Map([
  [Grades.Good, 'good'],
  [Grades.Neutral, 'neutral'],
  [Grades.Bad, 'bad'],
])

export const gradeToName = (grade) => {
  return gradeMap.get(grade) || Grades.Neutral
}

export default Grades
