import React from 'react'
import { GoogleLogin } from '@react-oauth/google'

import { AUTH_TOKEN_KEY } from '../../Consts'
import './GoogleLoginScreen.css'
import { NarrativelyLogo } from '../../components/narratively-logo/NarrativelyLogo.tsx'

const GoogleLoginScreen = () => {
  return (
    <div className={'google-login'}>
      <NarrativelyLogo />
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          localStorage.setItem(AUTH_TOKEN_KEY, credentialResponse.credential)
          window.location.reload()
        }}
        onError={() => {
          console.log('Login Failed')
        }}
        useOneTap
        auto_select
      />
    </div>
  )
}

export default GoogleLoginScreen
