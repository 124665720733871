import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import homeIcon from '../../assets/homeicons.svg'

import Type from './Type'
import './Home.css'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NarrativelyLogo } from '../../components/narratively-logo/NarrativelyLogo.tsx'

function Home() {
  const navigate = useNavigate()

  return (
    <div style={{ flex: 1 }}>
      <Container
        fluid
        className="home-section"
        id="home"
        style={{ height: '55vh' }}
      >
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{' '}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>{' '}
                I'M
              </h1>

              <NarrativelyLogo />
              <div
                style={{ padding: 30, position: 'absolute' }}
                className="type"
              >
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeIcon}
                alt="home pic"
                className="img-fluid"
                style={{ paddingTop: 50 }}
              />
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col md={3} style={{ paddingBottom: 20 }}>
              <Button
                className="start-button"
                variant="contained"
                onClick={() => {
                  navigate('/content-review')
                }}
                style={{
                  position: 'absolute',
                  marginTop: '20vh',
                  width: '250px',
                  height: '45px',
                  display: 'Flex',
                  justifyContent: 'center',
                }}
                onClickCapture={() => {
                  navigate('/content-review')
                }}
              >
                Start Here
                <span class="material-icons">arrow_right_alt</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  )
}

export default Home
