import React, { useMemo } from 'react'
import { jwtDecode } from 'jwt-decode'

const defaultContextValue = {
  userId: null,
  userEmail: null,
  userName: null,
}
const userContext = React.createContext(defaultContextValue)
export const useUserMetadata = () => React.useContext(userContext)

const decodeJWTAccessToken = (accessToken) => {
  const decodedToken = jwtDecode(accessToken)
  const userEmail = decodedToken.email
  const userId = decodedToken.sub
  const userName = decodedToken.name
  return {
    userId,
    userEmail,
    userName,
  }
}

export const UserContextProvider = ({ authToken, children }) => {
  const value = useMemo(() => {
    console.log(`accessToken in context provider: ${authToken}`)
    if (!authToken) {
      return defaultContextValue
    }
    const userMetadata = decodeJWTAccessToken(authToken)
    return userMetadata
  }, [authToken])

  return <userContext.Provider value={value}>{children}</userContext.Provider>
}
