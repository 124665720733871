import React, { useEffect, useRef, useState } from 'react'

import { Container } from 'react-bootstrap'
import tippy from 'tippy.js'

import Button from '@mui/material/Button'

import Audiences from './Audiences.js'
import AudiencePicker from './AudiencePicker.js'
import './ContentReview.css'
import apiInstance from '../../services/Api.js'
import Grades, { gradeToName } from './Grades.js'
import { useUserMetadata } from '../../hooks/useUserMetadata.js'
import ContentEditable from 'react-contenteditable'
import './ContentReview.css'
import Suggestions from './Suggestions.tsx'
import { NarrativelyLogo } from '../../components/narratively-logo/NarrativelyLogo.tsx'
import ParseTextLoader from '../../components/loaders/parse-text-loader/ParseTextLoader.jsx'
import { Sapling } from '@saplingai/sapling-js/observer'

export const CONTENT_GRADE = {
  good: 1,
  bad: 2,
}

interface GptSuggestion {
  id: number
  snippet: string
  explanation: string
  grade: number
}

const highlightSnippets = (text, comments) => {
  comments.forEach((comment) => {
    let snipIndex = text
      .replace('  ', ' ')
      .lastIndexOf(comment.snippet.replace('  ', ' '))

    var snippetId = 'snippet' + comment.id

    if (snipIndex !== -1) {
      const gradeStrike = comment.grade === Grades.Bad ? 'strike' : ''
      const gradeCssClassName = `grade-${gradeToName(comment.grade)}`

      text =
        text.substring(0, snipIndex) +
        `<Grid container justifyContent="center">
         <Tooltip title_disabled="${comment.explanation}">
         <span className="${gradeCssClassName} ${gradeStrike}"
         contenteditable spellcheck='false' id=${snippetId}>` +
        text.substring(snipIndex, snipIndex + comment.snippet.length) +
        `</span></Tooltip></Grid>` +
        text.substring(snipIndex + comment.snippet.length)
    }

    setTimeout(function () {
      tippy('#' + snippetId, {
        content: comment.explanation,
      })
    }, 100)
  })

  return text
}

document.addEventListener('paste', function (e) {
  // cancel paste
  e.preventDefault()

  // get text representation of clipboard
  var text = e?.clipboardData?.getData('text/plain')

  if (text) {
    text = text.replace(/\r\n/g, '\n')

    if (document.querySelectorAll('span[data-text="true"]').length === 0) {
      document.execCommand('insertText', false, text)
    } else {
      document.execCommand('undo', false)
      document.execCommand('insertText', false, text)
    }
  }
})

function ContentReview() {
  const [text, setText] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [gptSuggestions, setGptSuggestions] = useState<GptSuggestion[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [audience, setAudience] = useState(Audiences.GENERAL)
  const { userEmail } = useUserMetadata()
  const textRef = useRef<any>(null)

  useEffect(() => {
    Sapling.init({
      key: 'Y0177NWC71ZY2QH4AD3TW5KZTOG51VBS',
      endpointHostname: 'https://api.sapling.ai',
      editPathname: '/api/v1/edits',
      statusBadge: true,
      mode: 'dev',
    })

    const contentEditor = document.getElementById('content')
    if (contentEditor) {
      Sapling.observe(contentEditor)
    }
  })

  useEffect(() => {
    if (textRef !== undefined) {
      textRef.current.focus()
    }
  }, [textRef])

  const handleChange = (evt: any) => {
    setText(evt.target.value)
  }

  const handleDoubleClick = (): void => {
    // setWordToCheck(window.getSelection()?.toString());
  }

  const onClick = async () => {
    setSubmitted(true)
    setIsLoading(true)

    const selectedAudiences =
      audience === Audiences.GENERAL
        ? [Audiences.GENERAL]
        : [Audiences.GENERAL, Audiences.AMERICAN_LIBERALS]
    const gptResult = await apiInstance.post('/ai/processText', {
      text,
      audiences: selectedAudiences,
      userEmail,
    })

    const highlightedText = highlightSnippets(text, gptResult.data)
    setText(highlightedText)
    setGptSuggestions(gptResult.data)
    setIsLoading(false)
  }

  function triggerUnderlineAnimation() {
    var button = document.getElementById('underlineButton')
    button?.classList.add('underline-animation-triggered')
  }

  return (
    <Container fluid className="translate-post-section" id="content-review">
      <span className="logo-container">
        <NarrativelyLogo isSmallLogo={true} />
      </span>
      <div className="review-content-body">
        <ContentEditable
          className="review-content-text"
          autoFocus={true}
          onDoubleClick={handleDoubleClick}
          id="content"
          innerRef={textRef}
          html={text ? (text as string) : ''}
          onChange={(e) => handleChange(e)}
          data-placeholder="Type or paste (Ctrl + V) your text here to review content"
          // className={styles.doc__document__text}
        />

        <Suggestions
          suggestions={gptSuggestions.map((suggestion) => ({
            grade: suggestion.grade,
            releventSentence: suggestion.snippet,
            subject: 'usage of terror',
            explanation: suggestion.explanation,
            id: suggestion.id,
          }))}
        />
      </div>
      <div className="content-review-footer">
        <div className="footer-components">
          <div style={{ flex: 2, display: 'flex', justifyContent: 'end' }}>
            {isLoading && <ParseTextLoader />}
          </div>
          <div style={{ flex: 3, display: 'flex', justifyContent: 'start' }}>
            <Button
              variant="contained"
              onClick={onClick}
              style={{
                marginRight: '15px',
                background: 'var(--clr-primary)',
                height: '45px',
                marginTop: '10px',
              }}
              disabled={text.length === 0 || isLoading}
            >
              Review Content
              <span
                className="material-symbols-outlined"
                style={{
                  marginLeft: '5px',
                  fontSize: '20px',
                  paddingBottom: '3px',
                }}
              >
                planner_review
              </span>
            </Button>
            <AudiencePicker
              setAudience={setAudience}
              checkedAudience={audience}
              isDisabled={isLoading || text.length === 0}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}
export default ContentReview
