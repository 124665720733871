import React, { useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import Container from 'react-bootstrap/Container'
import logout from '../../utils/Logout'

import { Link } from 'react-router-dom'
import './Navbar.css'

const NavigateItem = ({ to, name }) => {
  return (
    <Nav.Item>
      <Nav.Link as={Link} to={to}>
        {name}
      </Nav.Link>
    </Nav.Item>
  )
}

const LogOutButton = () => {
  return (
    <Nav.Item>
      <Nav.Link onClick={logout}>Log Out</Nav.Link>
    </Nav.Item>
  )
}

function NavBar() {
  const [expand, updateExpanded] = useState(false)
  const [navColour, updateNavbar] = useState(false)

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true)
    } else {
      updateNavbar(false)
    }
  }

  window.addEventListener('scroll', scrollHandler)

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? 'sticky' : 'navbar'}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex"></Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : 'expanded')
          }}
        >
          <div className="toggleButton">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <NavigateItem to="/home" name="Home" />
            <NavigateItem to="/content-review" name="Review Content" />
            <NavigateItem to="/privacy" name="Privacy Policy" />
            <LogOutButton />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar
