import React from 'react'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import './Suggestions.css'
import { CONTENT_GRADE } from './ContentReview.tsx'

interface Suggestion {
  grade: number
  releventSentence: string
  subject: string
  explanation?: string
  id: number
}

const Suggestions = ({ suggestions }: { suggestions: Suggestion[] }) => {
  return (
    <div className="suggestions-container">
      <div className="suggestions-header">
        {suggestions.length === 0 ? (
          <Typography style={{ fontWeight: 'bold' }}>No suggestions</Typography>
        ) : (
          <>
            <span className="suggestions-number"> {suggestions.length}</span>
            <Typography style={{ fontWeight: 'bold' }}>
              All suggestions{' '}
            </Typography>
          </>
        )}
      </div>
      <List className="suggestions-list">
        {suggestions.map((suggestion) => {
          return (
            <ListItem key={suggestion.id} className="suggestion-card">
              <ListItemAvatar>
                {suggestion.grade === CONTENT_GRADE.good ? (
                  <span className="material-symbols-outlined good-grade">
                    done
                  </span>
                ) : (
                  <span className="material-symbols-outlined bad-grade">
                    undo
                  </span>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={suggestion.subject}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    ></Typography>
                    {suggestion.explanation}
                    {/* {" — I'll be in your neighborhood doing errands this…"} */}
                  </React.Fragment>
                }
              />
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}

export default Suggestions
