import React from 'react'
import logoDark from '../../assets/logoDark.png'
import './NarrativelyLogo.css'

export const NarrativelyLogo = ({
  isSmallLogo = false,
}: {
  isSmallLogo: boolean
}) => {
  return (
    <>
      {isSmallLogo ? (
        <span>
          <img
            src={logoDark}
            className="img-fluid logo"
            alt="brand"
            style={{ width: '40px', height: '40px', marginBottom: '10px' }}
          />
          <strong className={'small-main-name'}>arratively</strong>
        </span>
      ) : (
        <h1 className="heading-name">
          <img
            src={logoDark}
            className="img-fluid logo"
            alt="brand"
            style={{
              width: '100px',
              height: '100px',
              marginBottom: '9px',
            }}
          />
          <strong className={'main-name'}>arratively</strong>
        </h1>
      )}
    </>
  )
}
